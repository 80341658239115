var exports = {};

exports = exports = function (bytes) {
  var i = 0;

  while (i < bytes.length) {
    if ( // ASCII
    bytes[i] == 9 || bytes[i] == 10 || bytes[i] == 13 || 32 <= bytes[i] && bytes[i] <= 126) {
      i += 1;
      continue;
    }

    if ( // non-overlong 2-byte
    194 <= bytes[i] && bytes[i] <= 223 && 128 <= bytes[i + 1] && bytes[i + 1] <= 191) {
      i += 2;
      continue;
    }

    if ( // excluding overlongs
    bytes[i] == 224 && 160 <= bytes[i + 1] && bytes[i + 1] <= 191 && 128 <= bytes[i + 2] && bytes[i + 2] <= 191 || // straight 3-byte
    (225 <= bytes[i] && bytes[i] <= 236 || bytes[i] == 238 || bytes[i] == 239) && 128 <= bytes[i + 1] && bytes[i + 1] <= 191 && 128 <= bytes[i + 2] && bytes[i + 2] <= 191 || // excluding surrogates
    bytes[i] == 237 && 128 <= bytes[i + 1] && bytes[i + 1] <= 159 && 128 <= bytes[i + 2] && bytes[i + 2] <= 191) {
      i += 3;
      continue;
    }

    if ( // planes 1-3
    bytes[i] == 240 && 144 <= bytes[i + 1] && bytes[i + 1] <= 191 && 128 <= bytes[i + 2] && bytes[i + 2] <= 191 && 128 <= bytes[i + 3] && bytes[i + 3] <= 191 || // planes 4-15
    241 <= bytes[i] && bytes[i] <= 243 && 128 <= bytes[i + 1] && bytes[i + 1] <= 191 && 128 <= bytes[i + 2] && bytes[i + 2] <= 191 && 128 <= bytes[i + 3] && bytes[i + 3] <= 191 || // plane 16
    bytes[i] == 244 && 128 <= bytes[i + 1] && bytes[i + 1] <= 143 && 128 <= bytes[i + 2] && bytes[i + 2] <= 191 && 128 <= bytes[i + 3] && bytes[i + 3] <= 191) {
      i += 4;
      continue;
    }

    return false;
  }

  return true;
};

export default exports;